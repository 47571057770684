import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'components/Seo/Seo';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';
import Img from 'gatsby-image';
import Logo from 'components/Logo/Logo';

export const query = graphql`
  query Project(
    $slug: String!
    $previous: String
    $next: String
  ) {
    project: markdownRemark(
      fields: { slug: { eq: $slug } }
    ) {
      frontmatter {
        title
        description
        type
        skills
        date(formatString: "MMM, YYYY")
        cover {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
    previousProject: markdownRemark(
      fields: { slug: { eq: $previous } }
    ) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
    nextProject: markdownRemark(
      fields: { slug: { eq: $next } }
    ) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`;

export default function Project({ data }) {
  return (
    <React.Fragment>
      <Seo title={data.project.frontmatter.title} />
      <Nav />
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {/* This component requires Tailwind CSS >= 1.5.1 and data-todo-at-tailwindcss/ui >= 0-4-0 */}
      <div className="relative bg-white">
        <div className="">
          <div className="">
            {/* <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80"
              alt=""
            /> */}
            <Img
              fluid={
                data.project.frontmatter.cover
                  .childImageSharp.fluid
              }
              className="h-56 lg:h-72 w-full object-cover"
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto">
          <div className="lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-prose lg:prose-xl">
              <p className="leading-6 text-green-600 font-semibold tracking-wide uppercase">
                {data.project.frontmatter.type} &middot;{' '}
                {data.project.frontmatter.date}
              </p>
              {/* mb-8 */}
              <h1 className="mt-2 mb-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                {data.project.frontmatter.title}
              </h1>
              <div className="flex flex-row flex-wrap mb-8">
                {data.project.frontmatter.skills.map(
                  (skill) => (
                    <span class="flex-shrink-0 mr-2 mt-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800">
                      {skill}
                    </span>
                  ),
                )}
              </div>
              <p class="text-lg leading-7 text-gray-500 mb-5">
                {data.project.frontmatter.description}
              </p>
              {/* <div className="my-6">
                <p class="text-lg font-bold leading-7 text-gray-900">
                  Collaborators
                </p>
                {data.project.frontmatter.collaborators.map(
                  (collaborator) => (
                    <Collaborator
                      collaborator={collaborator}
                    />
                  ),
                )}
              </div> */}
              <div
                className="prose text-gray-500"
                dangerouslySetInnerHTML={{
                  __html: data.project.html,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

// export function Collaborator({ collaborator }) {
//   return (
//     <div className="mt-6 flex items-center">
//       <div className="flex-shrink-0">
//         {/* <a href="#"> */}
//         {/* <img
//           className="h-10 w-10 rounded-full"
//           src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
//           alt=""
//         /> */}
//         {collaborator.avatar ? (
//           <Img
//             fixed={
//               collaborator.avatar.childImageSharp.fixed
//             }
//             className="h-10 w-10 rounded-full"
//           />
//         ) : (
//           <div className="rounded-full overflow-hidden">
//             <Logo />
//           </div>
//         )}

//         {/* </a> */}
//       </div>
//       <div className="ml-3">
//         <p className="text-sm leading-5 font-medium text-gray-900">
//           {/* <a href="#" className="hover:underline"> */}
//           {collaborator.name}
//           {/* </a> */}
//         </p>
//         <div className="flex text-sm leading-5 text-gray-500">
//           {/* <time datetime="2020-03-16">Mar 16, 2020</time>
//           <span className="mx-1">&middot;</span>
//           <span>6 min read</span> */}
//           {collaborator.position}
//         </div>
//       </div>
//     </div>
//   );
// }